<template>
  <Loading v-if="isInitLoading" />
  <div v-else>
    <Header>确认申购订单</Header>
    <div v-for="item in tail" :key="item.index">
      <div class="box">
        <div class="shop">
          <img :src="item.showPic" alt="" />
          <div class="shop_text">
            <div class="shop_name">{{ item.goods_name }}</div>
            <div class="shop_serial">数量：{{ item.goods_num }}</div>
          </div>
        </div>

        <div class="payment_box">
          <div class="payment_way">支付方式</div>

          <div class="balance_box">
            <div class="balance_text">
              <img src="../../../static/image/remaining.png" alt="" />
              <div class="surplus">余额</div>
              <div class="surplus_num">（剩余¥{{ anyUserinfo.money }}）</div>
            </div>
            <input
              type="radio"
              name="radio"
              value="radio_1"
              @click="dionoe"
              id=""
            />
          </div>
          <div class="balance_box">
            <div class="balance_text">
              <img src="../../assets/images/account/wallet1.png" alt="" />
              <div class="surplus">易宝钱包</div>
              <div class="surplus_num"></div>
            </div>
            <input
              type="radio"
              name="radio"
              value="radio_1"
              @click="dioyibao"
              id=""
            />
          </div>
          <div class="balance_box" v-if="payChannelInfo.pay_channel == 1">
            <div class="balance_text">
              <img src="../../../static/image/icon.png" alt="" />
              <div class="surplus">支付宝</div>
            </div>
            <input type="radio" name="radio" value="radio_1" @click="diotow" />
          </div>
        </div>

        <div class="message_box" style="padding-top: 10px">
          <div class="purchase">
            <div class="purchase_name">购买信息</div>
            <div class="purchase_messy">{{ item.nickName }}</div>
          </div>

          <div class="purchase">
            <div class="purchase_name">创建时间</div>
            <div class="purchase_messy">{{ item.created_at }}</div>
          </div>

          <div class="purchase">
            <div class="purchase_name">订单编号</div>
            <div class="purchase_messy">
              <span style="font-size: 13px">{{ item.order_no }}</span>
              <img
                @click="copy(item.order_no)"
                src="../../../static/image/fuzhi@2x.png"
                alt=""
              />
            </div>
          </div>
          <div class="purchase">
            <div class="purchase_name">订单金额</div>
            <div class="purchase_num" style="color: #333">
              ¥{{ item.pay_price }}
            </div>
          </div>
          <div class="purchase">
            <div class="purchase_name">申购市值</div>
            <div class="purchase_num" style="color: #333">
              ¥{{ item.multiple_price }}
            </div>
          </div>
        </div>
        <div class="message_box">
          <div class="purchase">
            <div class="purchase_name">总金额</div>
            <div class="purchase_num">￥{{ item.pay_price }}</div>
          </div>
        </div>
        <div
          class="message_box"
          v-if="anyUserinfo.coupon_num && tail[0].couponCount"
        >
          <div
            class="purchase"
            @click="
              num = ticket_number;
              showPopUp = true;
            "
          >
            <div class="purchase_name chu">使用申购中签券</div>
            <div class="purchase_num" style="color: #333">
              <span v-if="ticket_number">{{ ticket_number }}</span
              ><span class="more"></span>
            </div>
          </div>
        </div>

        <div class="pay" @click="payment(item)">确认支付</div>
      </div>

      <van-overlay :show="show">
        <div class="wap">
          <div class="wap_affirm">
            <div @click="noe" class="cha">×</div>
            <div>请输入交易密码</div>
            <div class="forgotPwd" @click="$router.push('/forgotPwd')">
              忘记密码
            </div>
          </div>
          <div class="wap_form">订单金额</div>
          <div class="wap_num">￥{{ item.pay_price }}</div>
          <div class="wap_balance">
            余额<span
              >(￥
              {{ anyUserinfo.money }}
              )</span
            >
          </div>
          <!-- 密码输入框 -->
          <van-password-input
            :value="value"
            :error-info="errorInfo"
            :gutter="10"
            :focused="showKeyboard"
            @focus="showKeyboard = true"
            @input="specifiName($event)"
          />
          <!-- 数字键盘 -->
          <van-number-keyboard
            :show="showKeyboard"
            @input="onInput"
            @delete="onDelete"
            @blur="showKeyboard = true"
          />
        </div>
      </van-overlay>
    </div>

    <van-popup v-model:show="showPopUp" class="popUp">
      <div class="header">使用中签券</div>
      <div class="middle">
        <div class="num">
          <div
            class="left"
            @click="num <= 0 ? num : num--"
            :class="num <= 0 ? 'desable' : null"
          >
            -
          </div>
          <div class="input">
            <input
              type="text"
              @input="specifiName1($event)"
              @blur="blur($event)"
              v-model="num"
            />
          </div>
          <div
            class="right"
            @click="num >= (tail[0].couponCount || 0) ? num : num++"
            :class="num >= (tail[0].couponCount || 0) ? 'desable' : null"
          >
            +
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="left child">
          本次可用数量: <span>{{ tail[0].couponCount || 0 }}</span>
        </div>
        <div class="right child">
          我拥有的: <span>{{ anyUserinfo.coupon_num || 0 }}</span>
        </div>
      </div>
      <div class="btn">
        <div class="left" @click="showPopUp = false">取消</div>
        <div
          class="right"
          @click="
            ticket_number = num;
            showPopUp = false;
          "
        >
          确定
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import loading from "../../components/loading/loading.vue";
import { isSafari } from "../../utils/tools";
import { Dialog } from "vant";
export default {
  components: { loading },
  data() {
    return {
      value: "", //密码输入框
      errorInfo: "",
      showKeyboard: true,
      showPopUp: false,
      show: false,
      mystery: false,
      id: "",
      token: "",
      tail: [{}],
      anyUserinfo: {},
      radio: "1",
      num: 0,
      arr: "",
      radio_1: "", //单选框的值
      orderNo: "",
      isInitLoading: false,
      ticket_number: 0,
      payChannelInfo: {},
      showYiBao: false,
    };
  },
  beforeDestroy() {
    document.removeEventListener("visibilitychange", this.cb);
  },
  methods: {
    payChannel() {
      this.$api.payChannel().then((e) => {
        if (e.code === 0) {
          this.payChannelInfo = e.data || {};
        }
      });
    },
    copy(url) {
      //新建一个文本框
      let oInput = document.createElement("input");
      //赋值给文本框
      oInput.value = url;
      document.body.appendChild(oInput);
      // 选择对象;
      oInput.select();
      // 执行浏览器复制命令
      document.execCommand("Copy");
      //复制完成删除掉输入框
      oInput.remove();
      this.$toast("复制成功");
    },
    cb() {
      if (document.hidden) {
        // 页面被挂起
        console.log("页面被挂起");
      } else {
        console.log("页面呼出");
        if (!this.orderNo) return;
        // 页面呼出 支付宝购买后返回，刷新次数接口
        const query = {
          token: localStorage.getItem("token"),
          order_no: this.orderNo,
        };
        this.$api.loop(query).then((e) => {
          if (e.code != 100000) return this.$toast(e.msg);
          this.info = JSON.stringify(e.data);
          if (e.data.status === 2) {
            // this.$router.replace('/Allorder?type=2');
            this.$router.replace(`/unpaid?id=${this.tail[0].id}&orderType=3`);
            return this.$toast("支付成功");
          } else {
            // this.$router.replace('/Allorder?type=2');
            // this.$router.replace(`/unpaid?id=${this.tail[0].id}&orderType=3`);
            return this.$toast("支付失败");
          }
        });
      }
    },
    // 确认支付
    async payment(item) {
      if (this.radio_1 == "yibao") {
        let params = {
          token: localStorage.getItem("token"),
          pay_channel: "yee_wallet", // 余额支付
          order_no: this.tail[0].order_no,
          ticket_number: this.ticket_number,
        };
        this.$api.shengouPayJuhe(params).then((res) => {
          if (res.code == 100000) {
            this.$toast(res.msg);
            this.$router.replace(`/unpaid?id=${this.tail[0].id}&orderType=3`);
          } else if (res.code === 0) {
            // this.$router.push("/yibaoxd?url=" + res.url);
            window.location.href = res.url;
          } else {
            this.$toast(res.msg);
            this.value = "";
          }
        });
      } else if (this.radio_1 == "balance") {
        if (this.anyUserinfo.is_pay_password != 1) {
          return this.$toast("请前往安全中心设置交易密码");
        }
        this.show = true;
      } else if (this.radio_1 == "alipay") {
        const info = await this.$api.payChannel();
        if (info.code == 0) {
          if (info.data.pay_channel == 2) {
            this.payChannelInfo = info.data || {};
            return this.$toast("请使用余额支付");
          }
        }
        let params = {
          token: localStorage.getItem("token"),
          pay_channel: "alipay_wap",
          order_no: this.tail[0].order_no,
          ticket_number: this.ticket_number,
        };
        this.$api.shengouPayJuhe(params).then((res) => {
          if (res.code == 100000) {
            this.orderNo = item.order_no;
            if (isSafari()) {
              window.location.href = res.data.expend.pay_info;
            } else {
              this.$router.replace({
                path: "/Pay",
                query: {
                  payUrl: res.data.expend.pay_info,
                  orderNo: item.order_no,
                  id: this.tail[0].id,
                  orderType: 3,
                },
              });
            }
          } else {
            this.$toast(res.data.error_msg);
          }
        });
      } else {
        this.$toast("请选择支付方式");
      }
    },
    noe() {
      this.show = false;
      this.value = "";
    },
    specifiName() {
      // var that = this;
    },
    // 余额支付
    onInput(key) {
      if (this.value.length > 5) return;
      this.value = (this.value + key).slice(0, 6);
      if (this.value.length === 6) {
        let num = this.tail[0].order_number;
        let params = {
          token: localStorage.getItem("token"),
          pay_channel: "balance", // 余额支付
          // pay_channel: "alipay_wap", 支付宝支付
          order_no: this.tail[0].order_no,
          payPassword: this.value,
          ticket_number: this.ticket_number,
        };
        this.$api.shengouPayJuhe(params).then((res) => {
          if (res.code == 100000) {
            this.$toast(res.msg);
            // this.$router.push('/user');
            this.$router.replace(`/unpaid?id=${this.tail[0].id}&orderType=3`);
          } else {
            this.$toast(res.msg);
            this.value = "";
          }
        });
      } else {
        this.errorInfo = "";
      }
    },
    onDelete() {
      this.value = this.value.slice(0, this.value.length - 1);
    },

    // 选择支付方式
    dionoe(e) {
      if (Number(this.anyUserinfo.money) < Number(this.tail[0].pay_price)) {
        e.preventDefault();
        Dialog.confirm({
          title: "提示",
          message: "余额不足请前往充值,是否前往?",
          cancelButtonText: "取消",
          confirmButtonText: "前往",
        }).then(() => {
          this.$router.push("/topUp");
        });
        return;
      }
      this.radio_1 = "balance";
      let yin = document.querySelector(".pay");
      yin.style.color = "#ffffff";
      yin.style.backgroundColor = "#0754d3";
    },
    diotow() {
      this.radio_1 = "alipay";
      let yin = document.querySelector(".pay");
      yin.style.color = "#ffffff";
      yin.style.backgroundColor = "#0754d3";
    },
    dioyibao(e) {
      if (!this.showYiBao) {
        e.preventDefault();
        return this.$toast("敬请期待～");
      }
      if (this.anyUserinfo.yeePayWalletState) {
        this.radio_1 = "yibao";
        let yin = document.querySelector(".pay");
        yin.style.color = "#ffffff";
        yin.style.backgroundColor = "#0754d3";
      } else {
        e.preventDefault();
        Dialog.confirm({
          title: "提示",
          message: "是否前往开通易宝钱包",
          confirmButtonText: "去开通",
        }).then(() => {
          // on confirm
          this.$router.push("/yibao");
        });
      }
    },
    back() {
      this.mystery = true;
      // this.$router.back();
    },
    // 取消
    cannal() {
      let params = {
        token: localStorage.getItem("token"),
        id: this.tail[0].id,
      };
      this.$api.cancelUserOrder(params).then((res) => {
        if (res.code == 0) {
          this.$toast(res.msg);
          this.$router.back();
        }
      });
    },
    // 实时监听
    specifiName1(e) {
      let res = e.target.value.match(/^\d{0,8}/g)[0] || "";
      // if (res <= 0) res = 0;
      if (res > this.tail[0].couponCount) res = this.tail[0].couponCount;
      this.$nextTick(() => {
        this.num = res;
      });
    },
    blur(e) {
      let res = e.target.value.match(/^\d{0,8}/g)[0] || "";
      if (res <= 0) {
        res = 0;
        this.$nextTick(() => {
          this.num = res;
        });
      }
    },
  },
  mounted() {
    this.isInitLoading = false;
    this.payChannel();
    if (isSafari()) {
      document.addEventListener("visibilitychange", this.cb);
    }
    this.id = this.$route.query.id;

    try {
      this.tail = [JSON.parse(this.$route.query.info)];
    } catch (error) {
      this.tail = [];
    }

    //余额信息
    let params = {
      token: localStorage.getItem("token"),
    };
    this.$api.anyUserinfo(params).then((res) => {
      if (res.code == 0) {
        this.anyUserinfo = res.data;
      }
    });

    this.$api.anyRuleInfo({ type: "is_yeepay_open" }).then((e) => {
      if (e.data.rule == 1) {
        //
        this.showYiBao = true;
      }
    });
  },
};
</script>

<style lang="less" scoped>
.popUp {
  width: 80%;
  height: 200px;
  border-radius: 4px;
  .header {
    margin-bottom: 20px;
    font-size: 17px;
    margin-top: 17px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #000000;
    text-align: center;
  }
  .middle {
    flex: 2;
    font-size: 17px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #000000;
    text-align: center;
    .num {
      display: inline-flex;
      align-items: center;
      background: #f8f8f8;
      height: 40px;
      border-radius: 25px;
      .left {
        display: inline-flex;
        align-items: center;
        font-family: PingFang SC;
        font-weight: 400;
        color: #659fff;
        vertical-align: middle;
        padding: 0 20px;
      }
      .input {
        width: 100px;
        border-left: 1px solid #e5e5e5;
        border-right: 1px solid #e5e5e5;
        vertical-align: middle;
        input {
          border: none;
          outline: none;
          text-align: center;
          width: 100%;
          background: transparent;
          font-size: 16px;
          display: flex;
          align-items: center;
        }
      }
      .right {
        vertical-align: middle;
        padding: 0 20px;
        display: inline-flex;
        align-items: center;
        font-family: PingFang SC;
        font-weight: 400;
        color: #659fff;
      }
      .desable {
        color: #cecece;
      }
    }
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 40px 20px;
    .child {
      font-size: 13px;
      color: #999;
      span {
        color: #000000;
      }
    }
  }
  .btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;
    div {
      width: 74px;
      height: 27px;
      border-radius: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      &.left {
        color: #0754d3;
        border: 1px solid #cecece;
      }
      &.right {
        color: #fff;
        background: #0754d3;
      }
    }
  }
}
/deep/.van-password-input {
  position: relative;
  margin-bottom: 20px;
}
.wap_affirm {
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  div {
    flex: 2;
    text-align: center;
    font-size: 16px;
  }
  .cha {
    flex: 1;
    color: #666666;
    text-align: left;
    font-size: 30px;
  }
  .forgotPwd {
    flex: 1;
    text-align: right;
    font-size: 14px;
    color: rgb(rgb(7 84 211));
  }
}
.wap_balance {
  font-size: 10px;
  margin-bottom: 30px;
  span {
    color: #999999;
  }
}
.wap_form {
  font-size: 10px;
  margin-top: 20px;
}
.wap_num {
  height: 19px;
  font-size: 18px;
  font-weight: 400;
  color: #e76810;
  line-height: 20px;
  margin-top: 10px;
}
.wap {
  padding-top: 10px;
  width: 100%;
  background-color: #ffffff;
  height: 70%;
  position: absolute;
  bottom: 0;
  border-radius: 10px 10px 0px 0px;
  text-align: center;
}
.block {
  width: 120px;
  height: 120px;
  background-color: #fff;
}
.top {
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  img {
    width: 11px;
    height: 20px;
  }
  .order_text {
    font-size: 18px;
    margin-left: 38%;
  }
}

.box {
  background-color: #f3f4f8;
  width: 100%;
  height: 100vh;
  padding-top: 15px;
  .shop {
    background-color: #ffffff;
    display: flex;
    width: 331px;
    align-items: center;
    padding-top: 12px;
    border-radius: 5px;
    padding-bottom: 15px;
    padding-left: 10px;
    margin-left: 17px;
    img {
      width: 50px;
      margin-right: 13px;
      height: 50px;
    }
    .shop_text {
      .shop_name {
        font-size: 14px;
      }
      .shop_serial {
        font-size: 12px;
        color: #999999;
      }
    }
  }
  .payment_box {
    margin-top: 15px;
    border-radius: 5px;
    width: 331px;
    margin-left: 17px;
    background-color: #ffffff;
    padding-left: 10px;
    padding-bottom: 10px;
    .payment_way {
      font-size: 14px;
      padding-top: 18px;
    }
    .balance_box {
      display: flex;
      justify-content: space-between;
      padding-right: 10px;
      .balance_text {
        display: flex;
        align-items: center;
        line-height: 30px;
      }
      img {
        display: block;
        width: 21px;
        height: 20px;
        margin-right: 12px;
      }
      .surplus {
        font-size: 14px;
        color: #333333;
      }
      .surplus_num {
        color: #999999;
        font-size: 11px;
      }
    }
  }
  .message_box {
    margin-top: 10px;
    width: 341px;
    background-color: #ffffff;
    margin-left: 17px;
    border-radius: 5px;
    .purchase {
      display: flex;
      line-height: 38px;
      padding-left: 10px;
      justify-content: space-between;
      padding-right: 10px;
      font-size: 14px;
      .purchase_name {
        font-size: 14px;
        color: #999999;
        &.chu {
          color: #333333;
        }
      }
      .purchase_messy {
        color: #333333;
        display: flex;
        align-items: center;
        span {
        }
        img {
          display: block;
          width: 12px;
          height: 12px;
          margin-left: 10px;
        }
      }
      .purchase_num {
        color: #e76810;
        .more {
          display: inline-block;
          width: 7px;
          height: 7px;
          border-top: 2px solid #9999;
          border-right: 2px solid #9999;
          transform: rotate(45deg);
          margin-left: 5px;
        }
      }
    }
  }
  .pay {
    width: 200px;
    height: 40px;
    background-color: #eeeeee;
    color: #666666;
    font-size: 15px;
    text-align: center;
    line-height: 40px;
    border-radius: 5px;
    margin-top: 45px;
    margin-left: 22%;
  }
}

.mystery_nei {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .mystery_block {
    background-color: #fff;
    width: 250px;
    height: 150px;
    border-radius: 10px;
    .mystery_text {
      font-size: 16px;
      text-align: center;
      line-height: 50px;
      margin-top: 20px;
    }
    .mystery_but {
      display: flex;
      font-size: 14px;
      justify-content: space-around;
      margin-top: 20px;
      .mystery_tow {
        height: 30px;
        line-height: 30px;
        width: 80px;
        text-align: center;
        background-color: black;
        color: #ffffff;
      }
      .mystery_one {
        height: 30px;
        line-height: 30px;
        width: 80px;
        text-align: center;
        // background-color: ;
        border: 1px black solid;
      }
    }
  }
}

/deep/.van-password-input__security li {
  border: 2px #666666 solid;
  border-radius: 5px;
}
</style>
